import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useEventListener(eventName, handler, element, options) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);

    useIsomorphicLayoutEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        // Define the listening target
        const targetElement = element?.current ?? window;
        if (!(targetElement && targetElement.addEventListener)) return;
        // Create event listener that calls handler function stored in ref
        const listener = (event) => savedHandler.current(event);
        targetElement.addEventListener(eventName, listener, options);
        // Remove event listener on cleanup
        // eslint-disable-next-line consistent-return
        return () => {
            targetElement.removeEventListener(eventName, listener, options);
        };
    }, [eventName, element, options]);
}

/**
 * Trigger callback only once.
 *
 * @param handler - Handler that is called when deps change. Return true to prevent any further execution.
 * @param deps - Deps to run the effect handler.
 */
export function useEffectOnce(handler, deps) {
    const hasRan = useRef(false);

    useEffect(() => {
        if (hasRan.current) {
            return;
        }

        hasRan.current = handler();
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps

    return hasRan.current;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export function useHover(elementRef) {
    const [value, setValue] = useState(false);
    const handleMouseEnter = () => setValue(true);
    const handleMouseLeave = () => setValue(false);
    useEventListener('mouseenter', handleMouseEnter, elementRef);
    useEventListener('mouseleave', handleMouseLeave, elementRef);
    return value;
}
