import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import FinEst from '../../assets/img/finest.png';
import FundersPartners from '../../assets/img/funders-partners.png';
import { gettext, interpolate } from '../../utils/text';

const transVersion = gettext('Version: %s');

const Footer = () => (
    <footer className="main-footer">
        <Container fluid>
            <Row>
                <Col md={4}>
                    <div className="mt-2 mb-3">2023 TalTech</div>
                    <div className="mt-2 mb-3">
                        {interpolate(transVersion, [process.env.VERSION])}
                    </div>
                </Col>
                <Col md={8} className="text-right">
                    <Image fluid src={FinEst} alt="FinEst logo" />
                    <Image
                        fluid
                        src={FundersPartners}
                        alt="Funders partners image"
                    />
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;
