import React, { useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { gettext } from '../../utils/text';
import reverseUrl from '../../utils/urls';
import {
    selectSideBarMinimized,
    selectSideBarOpen,
    setSideBarMinimized,
} from '../../ducks/global';
import styles from './SideBar.scss';

import CaretDownIcon from '../../assets/icons/caret-down-icon.svg';
import HouseIcon from '../../assets/icons/house-icon.svg';
import BuildingIcon from '../../assets/icons/building-icon.svg';
import DatabaseIcon from '../../assets/icons/database-icon.svg';
import ThreeDotsIcon from '../../assets/icons/three-dots.svg';
import UsersIcon from '../../assets/icons/users-icon.svg';
import InfoIcon from '../../assets/icons/info-icon.svg';

const transDashboard = gettext('Dashboard');
const transInfo = gettext('Info');
const transBuildingData = gettext('Building Data');
const transData = gettext('Data');
const transSystem = gettext('SYSTEM');
const transOrganizations = gettext('Organizations');
const transBuildings = gettext('Buildings');
const transFloors = gettext('Floors');
const transRooms = gettext('Rooms');
const transTags = gettext('Tags');
const transDevices = gettext('Devices');
const transConstraintModalities = gettext('Constraint modalities');
const transZones = gettext('Zones');
const transConstraintRelations = gettext('Applied constraints');
const transUsers = gettext('Users');
const transRoles = gettext('Roles');
const transUserLogs = gettext('Logs');
const transDataOrder = gettext('Data order');

const organizationPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'locations.view_organization',
);
const buildingPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'locations.view_building',
);
const floorPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'locations.view_floor',
);
const roomPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'locations.view_room',
);
const zonePermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'locations.view_zone',
);
const tagPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'readings.view_tag',
);
const devicePermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'readings.view_device',
);
const constraintPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'alerts.view_constraints',
);
const dataPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'readings.add_tag',
);
const userPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'accounts.view_user',
);
const rolesPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'auth.view_group',
);
const logPermission = DJ_CONST.user.permissions.some(
    (permission) => permission === 'admin.view_logentry',
);

const SideBar = () => {
    const dispatch = useDispatch();

    const [buildingDataOpen, setBuildingDataOpen] = useState(false);
    const [userDataOpen, setUserDataOpen] = useState(false);
    const [dataOpen, setDataOpen] = useState(false);
    const [currentURL, setCurrentURL] = useState();

    const sideBarStatus = useSelector(selectSideBarOpen);
    const sideBarMinimizedStatus = useSelector(selectSideBarMinimized);

    function currentUrlChecker(location, collapseButton = false) {
        if (collapseButton) {
            switch (location) {
                case 'buildingData':
                    return [
                        'organizations',
                        'buildings',
                        'floors',
                        'rooms',
                        'zones',
                        'tags',
                        'devices',
                        'constraints',
                        'constraint_relations',
                    ].includes(currentURL);
                case 'data':
                    return ['data_order'].includes(currentURL);
                case 'userData':
                    return ['users', 'groups', 'user_log'].includes(currentURL);
                default:
                    return false;
            }
        }
        return location === currentURL;
    }

    const isUserSuperUser = useMemo(() => {
        const isSuperUser = DJ_CONST.user.is_superuser;
        if (isSuperUser) {
            return isSuperUser.toLowerCase() === 'true';
        }
        return false;
    }, []);

    useEffect(() => {
        const pathSplits = window.location.pathname.split('/');
        if (pathSplits.length > 2) {
            const currentPath = pathSplits[2] ? pathSplits[2] : pathSplits[1];
            setCurrentURL(currentPath);
            switch (currentPath) {
                case 'organizations':
                case 'buildings':
                case 'floors':
                case 'rooms':
                case 'zones':
                case 'tags':
                case 'devices':
                case 'constraints':
                case 'constraint_relations':
                    setBuildingDataOpen(true);
                    setDataOpen(false);
                    setUserDataOpen(false);
                    break;
                case 'data_order':
                    setBuildingDataOpen(false);
                    setDataOpen(true);
                    setUserDataOpen(false);
                    break;
                case 'users':
                case 'groups':
                case 'user_log':
                    setBuildingDataOpen(false);
                    setDataOpen(false);
                    setUserDataOpen(true);
                    break;
                default:
                    break;
            }
        }
    }, []);

    return (
        <nav
            className={`${styles.sidebar} ${
                sideBarStatus ? styles.sidebarOpen : ''
            }${
                sideBarMinimizedStatus && !sideBarStatus
                    ? styles.sidebarMinimized
                    : ''
            }`}
        >
            <div className={styles.sidebarWrapper}>
                <div className="px-3">
                    <Button
                        block
                        className={`py-2 my-3 ${
                            !currentUrlChecker('dashboard')
                                ? 'text-black-50'
                                : ''
                        }`}
                        variant={`${
                            currentUrlChecker('dashboard') ? 'primary' : 'light'
                        }`}
                        onClick={() =>
                            sideBarMinimizedStatus &&
                            dispatch(
                                setSideBarMinimized(!sideBarMinimizedStatus),
                            )
                        }
                        href={
                            !sideBarMinimizedStatus && reverseUrl('dashboard')
                        }
                    >
                        <div
                            className={`d-flex align-items-center ${
                                !sideBarMinimizedStatus
                                    ? 'flex-row'
                                    : 'flex-column'
                            }`}
                        >
                            <HouseIcon />
                            {!sideBarMinimizedStatus && (
                                <span className="ml-3">{transDashboard}</span>
                            )}
                        </div>
                    </Button>
                    <Button
                        block
                        className={`py-2 my-3 ${
                            !currentUrlChecker('info') ? 'text-black-50' : ''
                        }`}
                        variant={`${
                            currentUrlChecker('info') ? 'primary' : 'light'
                        }`}
                        onClick={() =>
                            sideBarMinimizedStatus &&
                            dispatch(
                                setSideBarMinimized(!sideBarMinimizedStatus),
                            )
                        }
                        href={!sideBarMinimizedStatus && reverseUrl('info')}
                    >
                        <div
                            className={`d-flex align-items-center ${
                                !sideBarMinimizedStatus
                                    ? 'flex-row'
                                    : 'flex-column'
                            }`}
                        >
                            <InfoIcon />
                            {!sideBarMinimizedStatus && (
                                <span className="ml-3">{transInfo}</span>
                            )}
                        </div>
                    </Button>
                    <div
                        className={`text-dark text-black-50 font-weight-bold my-3 px-2 ${
                            sideBarMinimizedStatus ? 'text-center' : ''
                        }`}
                    >
                        {sideBarMinimizedStatus ? (
                            <ThreeDotsIcon />
                        ) : (
                            transSystem
                        )}
                    </div>
                    <Nav className="flex-column">
                        <Nav.Item className="my-2">
                            <Button
                                aria-controls="buildingDataCollapse"
                                aria-expanded={buildingDataOpen}
                                block
                                active={
                                    !currentUrlChecker('buildingData', true) &&
                                    buildingDataOpen
                                }
                                className={`py-2 ${
                                    !currentUrlChecker('buildingData', true)
                                        ? 'text-black-50'
                                        : ''
                                }`}
                                variant={`${
                                    currentUrlChecker('buildingData', true)
                                        ? 'primary'
                                        : 'light'
                                }`}
                                onClick={() =>
                                    !sideBarMinimizedStatus
                                        ? setBuildingDataOpen(!buildingDataOpen)
                                        : dispatch(
                                              setSideBarMinimized(
                                                  !sideBarMinimizedStatus,
                                              ),
                                          )
                                }
                            >
                                <div
                                    className={`d-flex align-items-center ${
                                        !sideBarMinimizedStatus
                                            ? 'flex-row'
                                            : 'flex-column'
                                    }`}
                                >
                                    <BuildingIcon />
                                    {!sideBarMinimizedStatus && (
                                        <>
                                            <span className="ml-3">
                                                {transBuildingData}
                                            </span>
                                            <CaretDownIcon
                                                className={`${styles.caret} ${
                                                    buildingDataOpen
                                                        ? styles.open
                                                        : ''
                                                }    ml-auto`}
                                            />
                                        </>
                                    )}
                                </div>
                            </Button>
                            {!sideBarMinimizedStatus && (
                                <Collapse in={buildingDataOpen}>
                                    <div>
                                        <Nav
                                            id="dashboardCollapse"
                                            className="flex-column my-3"
                                        >
                                            {organizationPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl(
                                                        'organizations',
                                                    )}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'organizations',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>
                                                            {transOrganizations}
                                                        </li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {buildingPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl(
                                                        'buildings',
                                                    )}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'buildings',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>
                                                            {transBuildings}
                                                        </li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {floorPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('floors')}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'floors',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transFloors}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {roomPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('rooms')}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'rooms',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transRooms}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {zonePermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('zones')}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'zones',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transZones}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {tagPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('tags')}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'tags',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transTags}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {devicePermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('devices')}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'devices',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transDevices}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {constraintPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl(
                                                        'constraints',
                                                    )}
                                                    className="text-black-50 d-flex pl-5 mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'constraints',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>
                                                            {
                                                                transConstraintModalities
                                                            }
                                                        </li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {isUserSuperUser && (
                                                <>
                                                    <Nav.Link
                                                        as={Button}
                                                        href={reverseUrl(
                                                            'constraints',
                                                        )}
                                                        className="text-black-50 d-flex pl-5 text-left mb-1"
                                                        variant="light"
                                                        active={currentUrlChecker(
                                                            'constraints',
                                                        )}
                                                    >
                                                        <ul className="m-0 p-0">
                                                            <li>
                                                                {
                                                                    transConstraintModalities
                                                                }
                                                            </li>
                                                        </ul>
                                                    </Nav.Link>
                                                    <Nav.Link
                                                        as={Button}
                                                        href={reverseUrl(
                                                            'constraint_relations',
                                                        )}
                                                        className="text-black-50 d-flex pl-5 text-left mb-1"
                                                        variant="light"
                                                        active={currentUrlChecker(
                                                            'constraint_relations',
                                                        )}
                                                    >
                                                        <ul className="m-0 p-0">
                                                            <li>
                                                                {
                                                                    transConstraintRelations
                                                                }
                                                            </li>
                                                        </ul>
                                                    </Nav.Link>
                                                </>
                                            )}
                                        </Nav>
                                    </div>
                                </Collapse>
                            )}
                        </Nav.Item>
                        {dataPermission ? (
                            <Nav.Item className="my-2">
                                <Button
                                    aria-controls="dataCollapse"
                                    aria-expanded={dataOpen}
                                    active={
                                        !currentUrlChecker('data', true) &&
                                        dataOpen
                                    }
                                    block
                                    className={`py-2 ${
                                        !currentUrlChecker('data', true)
                                            ? 'text-black-50'
                                            : ''
                                    }`}
                                    variant={`${
                                        currentUrlChecker('data', true)
                                            ? 'primary'
                                            : 'light'
                                    }`}
                                    onClick={() =>
                                        !sideBarMinimizedStatus
                                            ? setDataOpen(!dataOpen)
                                            : dispatch(
                                                  setSideBarMinimized(
                                                      !sideBarMinimizedStatus,
                                                  ),
                                              )
                                    }
                                >
                                    <div
                                        className={`d-flex align-items-center ${
                                            !sideBarMinimizedStatus
                                                ? 'flex-row'
                                                : 'flex-column'
                                        }`}
                                    >
                                        <DatabaseIcon />
                                        {!sideBarMinimizedStatus && (
                                            <>
                                                <span className="ml-3">
                                                    {transData}
                                                </span>
                                                <CaretDownIcon
                                                    className={`${
                                                        styles.caret
                                                    } ${
                                                        dataOpen
                                                            ? styles.open
                                                            : ''
                                                    }    ml-auto`}
                                                />
                                            </>
                                        )}
                                    </div>
                                </Button>
                                {!sideBarMinimizedStatus && (
                                    <Collapse in={dataOpen}>
                                        <div>
                                            <Nav
                                                id="dataCollapse"
                                                className="flex-column my-3"
                                            >
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl(
                                                        'data_order',
                                                    )}
                                                    className="text-black-50 d-flex pl-5 text-left mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'data_order',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>
                                                            {transDataOrder}
                                                        </li>
                                                    </ul>
                                                </Nav.Link>
                                            </Nav>
                                        </div>
                                    </Collapse>
                                )}
                            </Nav.Item>
                        ) : null}
                        <Nav.Item className="my-2">
                            <Button
                                aria-controls="userCollapse"
                                aria-expanded={userDataOpen}
                                block
                                active={
                                    !currentUrlChecker('userData', true) &&
                                    userDataOpen
                                }
                                className={`py-2 ${
                                    !currentUrlChecker('userData', true)
                                        ? 'text-black-50'
                                        : ''
                                }`}
                                variant={`${
                                    currentUrlChecker('userData', true)
                                        ? 'primary'
                                        : 'light'
                                }`}
                                onClick={() =>
                                    !sideBarMinimizedStatus
                                        ? setUserDataOpen(!userDataOpen)
                                        : dispatch(
                                              setSideBarMinimized(
                                                  !sideBarMinimizedStatus,
                                              ),
                                          )
                                }
                            >
                                <div
                                    className={`d-flex align-items-center ${
                                        !sideBarMinimizedStatus
                                            ? 'flex-row'
                                            : 'flex-column'
                                    }`}
                                >
                                    <UsersIcon />
                                    {!sideBarMinimizedStatus && (
                                        <>
                                            <span className="ml-3">
                                                {transUsers}
                                            </span>
                                            <CaretDownIcon
                                                className={`${styles.caret} ${
                                                    userDataOpen
                                                        ? styles.open
                                                        : ''
                                                }    ml-auto`}
                                            />
                                        </>
                                    )}
                                </div>
                            </Button>
                            {!sideBarMinimizedStatus && (
                                <Collapse in={userDataOpen}>
                                    <div>
                                        <Nav
                                            id="userCollapse"
                                            className="flex-column my-3"
                                        >
                                            {userPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('users')}
                                                    className="text-black-50 d-flex text-left pl-5 mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'users',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transUsers}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {rolesPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl('groups')}
                                                    className="text-black-50 d-flex text-left pl-5 mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'groups',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transRoles}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                            {logPermission ? (
                                                <Nav.Link
                                                    as={Button}
                                                    href={reverseUrl(
                                                        'user_logs',
                                                    )}
                                                    className="text-black-50 d-flex text-left pl-5 mb-1"
                                                    variant="light"
                                                    active={currentUrlChecker(
                                                        'user_log',
                                                    )}
                                                >
                                                    <ul className="m-0 p-0">
                                                        <li>{transUserLogs}</li>
                                                    </ul>
                                                </Nav.Link>
                                            ) : null}
                                        </Nav>
                                    </div>
                                </Collapse>
                            )}
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
        </nav>
    );
};

export default SideBar;
