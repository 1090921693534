import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

/*
    This specific example doesn't necessarily require redux-thunk
    but this is a basic example of how you can return a function in your action.
    Mostly useful for complex logic that is required before dispatching the action (e.g API calls).
*/
export const setAsyncTable = createAsyncThunk(
    'title/setAsyncTable',
    async (items) => items,
);

const tableSlice = createSlice({
    name: 'table',
    initialState: {
        rows: [],
        page: 1,
        size: 25,
    },
    reducers: {
        setTable: (state, action) => {
            state.rows = action.payload;
        },
        setTablePage: (state, action) => {
            state.page = action.payload;
        },
        setTableSize: (state, action) => {
            state.size = action.payload;
        },
    },
    extraReducers: {
        [setAsyncTable.fulfilled]: (state, action) => {
            state.rows = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setTable, setTablePage, setTableSize } = tableSlice.actions;

export const selectTable = (state) => state.table.rows;
export const selectTablePage = (state) => state.table.page;
export const selectTableSize = (state) => state.table.size;

export default tableSlice.reducer;
