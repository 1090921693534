import { createSlice } from '@reduxjs/toolkit';
import { getCookie } from '../api';

function localeSelector(languageCookie) {
    let locale;
    switch (languageCookie) {
        case 'et':
            locale = 'et-EE';
            break;
        case 'en':
            locale = 'en-GB';
            break;
        default:
            locale = 'en-GB';
            break;
    }
    return locale;
}

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        navBarOpen: false,
        sideBarOpen: false,
        sideBarMinimized: false,
        locale: localeSelector(getCookie('django_language')),
    },
    reducers: {
        setNavBar: (state, action) => {
            state.navBarOpen = action.payload;
        },
        setSideBar: (state, action) => {
            state.sideBarOpen = action.payload;
        },
        setSideBarMinimized: (state, action) => {
            state.sideBarMinimized = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setNavBar,
    setSideBar,
    setSideBarMinimized,
} = globalSlice.actions;

export const selectNavBarOpen = (state) => state.global.navBarOpen;
export const selectSideBarOpen = (state) => state.global.sideBarOpen;
export const selectSideBarMinimized = (state) => state.global.sideBarMinimized;
export const selectLocale = (state) => state.global.locale;

export default globalSlice.reducer;
