import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setAsyncFilter = createAsyncThunk(
    'filter/setAsyncFilter',
    async (items) => items,
);

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        data: null,
    },
    reducers: {
        setFilter: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: {
        [setAsyncFilter.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
        [setAsyncFilter.rejected]: (state) => {
            state.data = null;
        },
    },
});

export const { setFilter } = filterSlice.actions;

export const selectFilter = (state) => state.filter.data;

export default filterSlice.reducer;
