import { combineReducers } from '@reduxjs/toolkit';

import titleReducer from './ducks/title';
import tableReducer from './ducks/table';
import globalReducer from './ducks/global';
import filterReducer from './ducks/filter';
import timelineReducer from './ducks/timeline';

const rootReducer = combineReducers({
    title: titleReducer,
    table: tableReducer,
    global: globalReducer,
    filter: filterReducer,
    timeline: timelineReducer,
});

export default rootReducer;
