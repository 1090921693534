import { createSlice } from '@reduxjs/toolkit';

const timelineSlice = createSlice({
    name: 'timeline',
    initialState: {
        data: [],
        page: 1,
        size: 25,
        filter: null,
        loading: false,
        refresh: false,
    },
    reducers: {
        setTimelineData: (state, action) => {
            state.rows = action.payload;
        },
        setTimelinePage: (state, action) => {
            state.page = action.payload;
        },
        setTimelineSize: (state, action) => {
            state.size = action.payload;
        },
        setTimelineFilter: (state, action) => {
            state.filter = action.payload;
        },
        setTimelineLoading: (state, action) => {
            state.loading = action.payload;
        },
        setRefreshTimeline: (state, action) => {
            state.refresh = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setTimelineData,
    setTimelinePage,
    setTimelineSize,
    setTimelineFilter,
    setTimelineLoading,
    setRefreshTimeline,
} = timelineSlice.actions;

export const selectTimelineData = (state) => state.timeline.data;
export const selectTimelinePage = (state) => state.timeline.page;
export const selectTimelineSize = (state) => state.timeline.size;
export const selectTimelineFilter = (state) => state.timeline.filter;
export const selectTimelineLoading = (state) => state.timeline.loading;
export const selectTimelineRefresh = (state) => state.timeline.refresh;
export default timelineSlice.reducer;
